import { queryOptions } from '@tanstack/react-query';
import { invoke } from '@tauri-apps/api/core';

import { AppCapability, isCapable } from '@/utils/capabilities';

export const isAccessibilityPermissionsGrantedQueryOptions = () =>
  queryOptions({
    queryKey: ['is-accessibility-permissions-granted'],
    retry: true,
    retryDelay: 1000,
    queryFn: async () => {
      if (!isCapable(AppCapability.ContextCapture))
        throw new Error('Accessbility permission is not available in browser');

      const isGranted = await invoke<boolean>(
        'check_accessibility_permissions'
      );
      if (!isGranted)
        throw new Error('Oops! Accessbility permission is not granted');

      return isGranted;
    },
  });

export const checkAccessibilityPermissionsQueryOptions = () =>
  queryOptions({
    queryKey: ['check-accessibility-permission'],
    queryFn: () => invoke<boolean>('check_accessibility_permissions'),
    retry: false,
  });
