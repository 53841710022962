import { queryOptions } from '@tanstack/react-query';
import { z } from 'zod';

import { logoutUser } from '@/utils/auth';
import { fetchApiWithAuth } from '@/utils/fetch';
import { setLocalStorageItem } from '@/utils/localStorage';
import { identify_person } from '@/utils/posthog';

const UserSchema = z.object({
  sub: z.string(),
  email: z.string(),
  allowed_chat_modes: z.array(z.string()).default([]),
  global_prompt: z.string().nullable(),
  info_to_ignore_prompt: z.string().nullable(),
  todo_prompt: z.string().nullable(),
  summary_prompt: z.string().nullable(),
  latest_input: z.string().nullable(),
  total_inputs: z.number(),
  name: z.string().nullable(),
  aliases: z.string().nullable(),
});

export type User = z.infer<typeof UserSchema>;

export const USER_QUERY_KEY = ['user'];

export const userQueryOptions = () =>
  queryOptions({
    queryKey: USER_QUERY_KEY,
    retry: 2,
    queryFn: async () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await fetchApiWithAuth('get_user_data', {
        headers: {
          TZ: timezone,
        },
      });

      if (!response.ok) {
        throw new Error('unable to fetch user data');
      }

      const data = await response.json();

      const user = UserSchema.parse(data);

      identify_person(user.email);
      setLocalStorageItem('user', user);

      return user;
    },
  });

export const userMutationOptions = () => ({
  mutationKey: ['user'],
  mutationFn: async (data: Partial<User>) => {
    await fetchApiWithAuth('update_user_data', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },
});

export const deleteUserMutationOptions = () => ({
  mutationKey: ['delete-user'],
  mutationFn: async () => {
    const response = await fetchApiWithAuth('delete_user', { method: 'POST' });

    if (!response.ok) {
      throw new Error('Unable to delete user account');
    }

    return response.json();
  },
  onSuccess: () => {
    logoutUser();
  },
});
