import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { queryClient } from '@/query/client';
import { checkAccessibilityPermissionsQueryOptions } from '@/query/permissions';
import { isAuthenticated } from '@/utils/auth';
import { AppCapability, isCapable } from '@/utils/capabilities';
import { collect_user_config } from '@/utils/posthog';

export const Route = createFileRoute('/_main/')({
  beforeLoad: async ({ location }) => {
    if (!isAuthenticated()) {
      posthog.capture('redirect', {
        to: '/login',
        from: '/_main/',
      });
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }

    if (isCapable(AppCapability.DesktopMainWindowExclusive)) {
      const hasAccessibilityPermissions = await queryClient.fetchQuery(
        checkAccessibilityPermissionsQueryOptions()
      );

      if (!hasAccessibilityPermissions) {
        posthog.capture('redirect', {
          to: '/grant-permissions',
          from: '/_main/',
        });
        throw redirect({
          to: '/grant-permissions',
          replace: true,
        });
      }
    }
  },

  onEnter() {
    collect_user_config();
  },
});
