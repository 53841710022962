import { invoke } from '@tauri-apps/api/core';
import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';

import {
  commandCallbackEnabledAtom,
  modifierKeyAtom,
  tapCountAtom,
  tapIntervalAtom,
} from '@/stores/atoms/settings';
import { AppCapability, isCapable } from '@/utils/capabilities';

export function useSetupCommandCallback() {
  const isInit = useRef(false);

  const isCommandCallbackEnabled = useAtomValue(commandCallbackEnabledAtom);
  const modifierKey = useAtomValue(modifierKeyAtom);
  const tapCount = useAtomValue(tapCountAtom);
  const tapInterval = useAtomValue(tapIntervalAtom);

  useEffect(() => {
    if (isInit.current || !isCapable(AppCapability.HummingBirdCommandShortcut))
      return;

    isInit.current = true;

    if (isCommandCallbackEnabled) {
      invoke('register_command_callback', {
        modifier: modifierKey,
        taps: tapCount,
        interval: tapInterval,
      });
    } else {
      invoke('disable_command_callback');
    }
  }, [isInit, isCommandCallbackEnabled, modifierKey, tapCount, tapInterval]);
}
