export interface ErrorResponse {
  message: string;
  code: string;
}

export interface CallbackResponse<T> {
  status: string;
  payload?: T;
  error?: ErrorResponse;
}

export type Source = {
  text: string;
  app: string;
};

export enum TodoStatus {
  suggestion = 'suggestion',
  accepted = 'accepted',
  rejected = 'rejected',
  completed = 'completed',
  deleted = 'deleted',
}

// The backend used to return only description.
// The title is about one sentence long.
// The description is about 4 sentences long.
export type Todo = {
  order: number;
  id: number;
  title: string;
  description: string;
  status: TodoStatus;
  date: string;
  categories: string[];
};

export type TodoCreate = {
  title: string;
  description: string;
  status: TodoStatus;
  date: string;
  categories: string[];
};

export type TodoUpdate = {
  id: number;
  description: string;
  status: TodoStatus;
  date: string;
  categories: string[];
};

export enum ChatStatus {
  NOT_SUBMITTED = 0, // Could maybe rename to IDLE, though this may imply no activity (but actually user can be typing)
  THINKING = 10, // Answer in progress, but assistant hasn't yet sent any tokens in the current message
  STREAMING = 20, // Assistant is in the process of sending tokens
}

export type PartialAnswer = {
  // TODO: Rename to something like ActiveChat
  isThinking: boolean;
  status: ChatStatus; // Meant to replace isThinking and isStreaming (and maybe isStoppedByUser down the line)
  // and provide more information (such as whether the particular chat is in the process of response generation -
  // currently isStreaming applies to all chats at once, which causes some issues with Stop button showing even if
  // the user switches to a new chat)
  partialAnswer: string;
  messageIndex: number;
  isStoppedByUser?: boolean;
};

export type PartialAnswers = {
  // TODO: Rename to something like ActiveChats
  [chatId: number]: PartialAnswer;
};

// TODO: Bigger refactor: it's unnecessarily confusing and error-prone to have two separate ways of storing
// chat information:
// 1. As a list of chats
// 2. As a dictionary of PartialAnswers

export type Category = {
  id: number;
  name: string;
  capture: string | null;
  ignore: string | null;
};

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

export interface WindowInfo {
  title: string;
  position: Position;
  size: Size;
}

export interface ActiveApp {
  name: string;
  pid: number;
  iconBase64: string | null;
  windows: string[];
}

interface Frame {
  position: Position;
  size: Size;
}

export interface FocusedElement {
  role: string;
  description: string;
  value?: string;
  frame?: Frame;
  selection?: string;
}
