import * as path from '@tauri-apps/api/path';
import { readFile } from '@tauri-apps/plugin-fs';

import { logError } from '@/lib/errorLogging';

export async function getFileFromPath(filePath: string): Promise<File> {
  // try {
  // Handle blob URLs (from clipboard or from attachemnts in the web app)
  if (filePath.startsWith('blob:')) {
    const response = await fetch(filePath);
    const blob = await response.blob();

    // Try to determine a more appropriate filename and type
    // If blob has type info, use it, otherwise default to octet-stream
    const type = blob.type || 'application/octet-stream';

    // Default filename with proper extension based on MIME type
    let filename = 'file.txt';
    if (type === 'image/png') filename = 'image.png';
    else if (type === 'image/jpeg') filename = 'image.jpg';
    else if (type === 'image/gif') filename = 'image.gif';
    else if (type === 'application/pdf') filename = 'document.pdf';
    else if (
      type === 'application/msword' ||
      type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      filename = 'document.docx';
    } else if (type === 'text/plain') filename = 'document.txt';
    else if (type === 'text/csv') filename = 'data.csv';

    return new File([blob], filename, {
      type: type,
    });
  }

  // Read the file content as binary
  const binaryData = await readFile(filePath);

  // Get filename and extension
  const fileName = await path.basename(filePath);
  const fileExt = await path.extname(filePath);

  // Create the File object
  const blob = new Blob([binaryData]);
  const file = new File([blob], fileName, {
    type: getMimeType(fileExt),
  });

  return file;
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function getFilesFromPaths(filesPaths: string[]) {
  const files = [];

  try {
    for (const filePath of filesPaths) {
      files.push(await getFileFromPath(filePath));
    }
  } catch (error) {
    logError(error, 'Failed to get files from paths');
  }

  return files;
}

export async function validateFile(
  filePath: string,
  options?: {
    maxSize?: number;
    allowedTypes?: string[];
  }
): Promise<boolean> {
  try {
    const fileInfo = await getFileFromPath(filePath);

    if (options?.maxSize && fileInfo.size > options.maxSize) {
      throw new Error(
        `File size exceeds maximum allowed size of ${options.maxSize} bytes`
      );
    }

    if (options?.allowedTypes && options.allowedTypes.length > 0) {
      const fileType = getMimeType(await path.extname(filePath));
      if (!options.allowedTypes.includes(fileType)) {
        throw new Error(`File type ${fileType} is not allowed`);
      }
    }

    return true;
  } catch (error) {
    throw new Error(`File validation failed: ${error}`);
  }
}

export const mimeTypes: Record<string, string> = {
  // Images
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',

  // Documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  // Text
  txt: 'text/plain',
  csv: 'text/csv',
  md: 'text/markdown',
  json: 'application/json',

  // Archives
  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
  '7z': 'application/x-7z-compressed',
};

function getMimeType(extension: string): string {
  const ext = extension.toLowerCase().replace('.', '');
  return mimeTypes[ext];
}
