import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { queryClient } from '@/query/client';
import { onboardingQueryOptions } from '@/query/onboarding';

export const Route = createFileRoute('/onboarding')({
  beforeLoad: () => {
    if (!posthog.isFeatureEnabled('state-aware-onboarding')) {
      posthog.capture('redirect', {
        to: '/',
        from: '/onboarding',
      });
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  loader: () => {
    const getOnboarding = async () => {
      try {
        return await queryClient.ensureQueryData(onboardingQueryOptions());
      } catch (_error) {
        return null;
      }
    };
    return {
      deferredOnboarding: getOnboarding(),
    };
  },
});
