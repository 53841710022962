import { getEnvVar } from '@/env';
import { useAppStore } from '@/store';

export const debugToast = (description: string) => {
  if (!getEnvVar('ENABLE_DEVTOOLS')) return;
  if (!import.meta.env.DEV) return;
  if (window.location.pathname === '/meeting-status') return;

  useAppStore.getState().setToastData({
    title: '🤣 DEBUG',
    description,
    kind: 'info',
  });
};
