import { getCurrentWindow } from '@tauri-apps/api/window';
import { platform } from '@tauri-apps/plugin-os';

// @ts-ignore
export const inBrowser = () => window.__TAURI_INTERNALS__ === undefined;

type SupportedPlatform = 'macos' | 'android' | 'ios' | 'web';
type WindowType = 'main' | 'spotlight' | 'mini-chat';

const WINDOW_LABEL_MAP: Record<string, WindowType> = {
  main: 'main',
  'little-bird-spotlight': 'spotlight',
  'little-bird-mini-chat': 'mini-chat',
};

export enum AppCapability {
  // Core capabilities
  ContextCapture = 'CONTEXT_CAPTURE',
  VoiceInput = 'VOICE_INPUT',
  CheckForUpdates = 'CHECK_FOR_UPDATES',
  FocusChatListener = 'FOCUS_CHAT_LISTENER',
  BackgroundAuthTokenRefresh = 'BACKGROUND_AUTH_TOKENS_REFRESH',
  StartupAtLogin = 'STARTUP_AT_LOGIN',
  HummingBirdCommandShortcut = 'HUMMING_BIRD_COMMAND_SHORTCUT',
  HotKeys = 'HOT_KEYS',
  Onboarding = 'ONBOARDING',
  AutoUpdate = 'AUTO_UPDATE',
  WebkitBackdropFilterFix = 'WEBKIT_BACKDROP_FILTER_FIX',
  TitleBar = 'TITLEBAR',

  // Platform-specific capabilities
  DesktopExclusive = 'DESKTOP_EXCLUSIVE',
  MobileExclusive = 'MOBILE_EXCLUSIVE',
  WebExclusive = 'WEB_EXCLUSIVE',

  // Window-specific capabilities
  MainWindowExclusive = 'MAIN_WINDOW_EXCLUSIVE',
  DesktopMainWindowExclusive = 'DESKTOP_MAIN_WINDOW_EXCLUSIVE',
  SpotlightExclusive = 'SPOTLIGHT_EXCLUSIVE',
  MiniChatExclusive = 'MINI_CHAT_EXCLUSIVE',
}

const capabilityConstraintsMap: Record<
  AppCapability,
  {
    supportedPlatforms: SupportedPlatform[];
    supportedWindows: WindowType[];
  }
> = {
  [AppCapability.ContextCapture]: {
    supportedPlatforms: ['macos', 'android'],
    supportedWindows: ['main', 'spotlight'],
  },
  [AppCapability.VoiceInput]: {
    supportedPlatforms: ['macos', 'android'],
    supportedWindows: ['main'],
  },
  [AppCapability.CheckForUpdates]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.FocusChatListener]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.HummingBirdCommandShortcut]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.StartupAtLogin]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.HotKeys]: {
    supportedPlatforms: ['macos', 'web'],
    supportedWindows: ['main'],
  },
  [AppCapability.Onboarding]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.AutoUpdate]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.WebkitBackdropFilterFix]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main', 'mini-chat'],
  },
  [AppCapability.TitleBar]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.DesktopExclusive]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main', 'spotlight', 'mini-chat'],
  },
  [AppCapability.MobileExclusive]: {
    supportedPlatforms: ['android', 'ios'],
    supportedWindows: ['main'],
  },
  [AppCapability.WebExclusive]: {
    supportedPlatforms: ['web'],
    supportedWindows: ['main'],
  },
  [AppCapability.MainWindowExclusive]: {
    supportedPlatforms: ['macos', 'android', 'ios', 'web'],
    supportedWindows: ['main'],
  },
  [AppCapability.DesktopMainWindowExclusive]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['main'],
  },
  [AppCapability.SpotlightExclusive]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['spotlight'],
  },
  [AppCapability.MiniChatExclusive]: {
    supportedPlatforms: ['macos'],
    supportedWindows: ['mini-chat'],
  },
  [AppCapability.BackgroundAuthTokenRefresh]: {
    supportedPlatforms: ['macos', 'android'],
    supportedWindows: ['main'],
  },
};

/**
 * Checks if a capability is available in the current environment
 * @param capability - The capability to check
 * @returns boolean indicating if the capability is available
 */
export const isCapable = (capability: AppCapability): boolean => {
  const currentPlatform = inBrowser()
    ? 'web'
    : ((platform?.() ?? 'web') as SupportedPlatform);
  const currentWindowLabel = inBrowser()
    ? 'main'
    : (getCurrentWindow?.()?.label ?? 'main');
  const currentWindow = WINDOW_LABEL_MAP[currentWindowLabel];

  const { supportedPlatforms, supportedWindows } =
    capabilityConstraintsMap[capability];

  if (!supportedPlatforms.includes(currentPlatform)) {
    console.warn(
      `Capability ${capability} is not available on platform ${currentPlatform}`
    );
    return false;
  }

  if (!supportedWindows.includes(currentWindow)) {
    console.warn(
      `Capability ${capability} is not available in window ${currentWindowLabel}`
    );
    return false;
  }

  return true;
};

export const inDesktop = () => isCapable(AppCapability.DesktopExclusive);
export const isDesktopMainWindow = () =>
  isCapable(AppCapability.DesktopMainWindowExclusive);
export const inMobile = () => isCapable(AppCapability.MobileExclusive);
