import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { useAppStore } from '../store';

import { API_URL } from './api';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY } from './auth';
import { getLocalStorageItem } from './localStorage';
import { retryWithBackoff } from './promise';
import { debugToast } from './toast';

import { IS_AUTH_VALID_QUERY_KEY } from '@/query/auth';
import { queryClient } from '@/query/client';

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(
    async (request) => {
      const accessToken = getLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
      if (accessToken) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // Handle 401 Unauthorized errors (token expired)
      if (error.response?.status === 401) {
        debugToast('Axios encountered a 401, requesting token refresh....');

        queryClient.invalidateQueries({
          queryKey: IS_AUTH_VALID_QUERY_KEY,
          refetchType: 'all',
        });
      }

      const message = error.response?.data?.detail?.user_error_message;

      if (message) {
        useAppStore.getState().setToastData({
          title: 'Error',
          description: message,
          kind: 'error',
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

let axiosInstance: AxiosInstance | null = null;

const wrapWithRetry = (instance: AxiosInstance): AxiosInstance => {
  const wrappedInstance = Object.create(instance);

  (
    ['get', 'post', 'put', 'delete', 'patch'] as Array<keyof AxiosInstance>
  ).forEach((method) => {
    wrappedInstance[method] = async <T = unknown, R = AxiosResponse<T>>(
      ...args: [AxiosRequestConfig] | [string, AxiosRequestConfig]
    ): Promise<R> => {
      return retryWithBackoff(() => (instance[method] as Function)(...args));
    };
  });

  return wrappedInstance;
};

export const getAxiosInstance = (): AxiosInstance => {
  if (axiosInstance) return axiosInstance;

  axiosInstance = wrapWithRetry(createAxiosInstance(API_URL));
  return axiosInstance;
};
