import { captureException } from '@sentry/react';
import { ErrorComponentProps } from '@tanstack/react-router';
import { useEffect } from 'react';

import Logo from '@/assets/icons/logo.svg';
import Button from '@/components/ui/Button';
import CenteredTile from '@/components/ui/CenteredTile';
import { useErrorReception } from '@/hooks/useErrorReception';
import { logoutUser } from '@/utils/auth';
import { handleMailTo } from '@/utils/platform';

export function ErrorScreen({ error, reset }: ErrorComponentProps) {
  const { title, message, action, actionLabel } = useErrorReception(error);

  const handleOnPress = () => {
    reset();

    action();
  };

  const handleResetApp = () => {
    reset();

    logoutUser();

    localStorage.clear();

    sessionStorage.clear();
  };

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <CenteredTile
      title={title}
      description={message}
      leadingContent={<img src={Logo} alt="Little Bird" className="mx-auto" />}
      content={
        <>
          <Button className="w-full mb-6" type="button" onPress={handleOnPress}>
            {actionLabel}
          </Button>

          <p className="mt-2 text-center text-darkSecondary text-sm">
            Keep having trouble?{' '}
            <span
              className="text-darkPrimary cursor-pointer underline"
              onClick={handleResetApp}
            >
              Reset the app
            </span>{' '}
            or{' '}
            <span
              className="text-darkPrimary cursor-pointer underline"
              onClick={() => {
                handleMailTo(
                  'support@genos.dev',
                  'I need assistance',
                  `I need help with the following issue: ${message}`
                );
              }}
            >
              Contact Support
            </span>
          </p>
        </>
      }
    />
  );
}
