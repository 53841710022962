import { createFileRoute, Outlet } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { useAuthTokensRefresh } from '@/hooks/useAuthTokensRefresh';
import { useSetupCommandCallback } from '@/hooks/useCommandCallback';
import { useFocusedChatListener } from '@/hooks/useFocusedChatListener';
import { UpdateProvider, useUpdate } from '@/providers/UpdateProvider';
import UpdateScreen from '@/screens/UpdateScreen';

export const Route = createFileRoute('/_main')({
  component: MainLayout,
});

function MainLayout() {
  useAuthTokensRefresh();
  useSetupCommandCallback();
  useFocusedChatListener();
  return (
    <UpdateProvider>
      <Layout>
        <Outlet />
      </Layout>
    </UpdateProvider>
  );
}

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const { shouldForceUpdateScreen } = useUpdate();

  if (shouldForceUpdateScreen) return <UpdateScreen />;

  return <>{children}</>;
}
