import posthog from 'posthog-js';

interface ErrorLogOptions {
  context?: string;
  metadata?: Record<string, unknown>;
}

export const logError = (
  error: unknown,
  message: string,
  options?: ErrorLogOptions
) => {
  // Console logging with more context
  console.error(`[${options?.context}] ${message}:`, error);

  // Analytics tracking
  posthog.capture('error', {
    error: error instanceof Error ? error.message : String(error),
    errorStack: error instanceof Error ? error.stack : undefined,
    message,
    context: options?.context,
    timestamp: new Date().toISOString(),
    ...options?.metadata,
  });

  // You could also integrate with error monitoring services here
  // For example: Sentry.captureException(error, { extra: { message, ...options } });
};
