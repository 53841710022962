import { queryOptions } from '@tanstack/react-query';
import { invoke } from '@tauri-apps/api/core';

import { AppCapability, isCapable } from '@/utils/capabilities';
import {
  startContextCollection,
  StartContextCollectionOptions,
  stopContextCollection,
} from '@/utils/context_collection';

export const IS_COLLECTING_CONTEXT_QUERY_KEY = ['is-collecting-context'];

export const isCollectingContextQueryOptions = () =>
  queryOptions({
    queryKey: IS_COLLECTING_CONTEXT_QUERY_KEY,
    retry: true,
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    queryFn: async () => {
      if (!isCapable(AppCapability.ContextCapture)) return;
      const isAccessibilityPermissionsGranted = await invoke<boolean>(
        'check_accessibility_permissions'
      );

      if (!isAccessibilityPermissionsGranted)
        throw new Error('accessibility permissions are not granted');

      return invoke<boolean>('context_collection_working');
    },
  });

export const startContextCollectionMutationOptions = () => ({
  mutationKey: ['start-context-collection'],
  retry: false,
  mutationFn: async (options: StartContextCollectionOptions) =>
    startContextCollection(options),
});

export const stopContextCollectionMutationOptions = () => ({
  mutationKey: ['stop-context-collection'],
  retry: false,
  mutationFn: async () => stopContextCollection(),
});
