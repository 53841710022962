import {
  DefaultError,
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
} from '@tanstack/react-query';

import { router } from '@/router';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  refreshAuthTokens,
} from '@/utils/auth';
import { AppCapability, isCapable } from '@/utils/capabilities';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage';
import { debugToast } from '@/utils/toast';

let isRefreshing = false;

let failedQueue: {
  query?: Query<unknown, unknown>;
  mutation?: Mutation<unknown, unknown, unknown, unknown>;
  variables?: unknown;
}[] = [];

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const processFailedQueue = () => {
  failedQueue.forEach(({ query, mutation, variables }) => {
    if (mutation) {
      const { options } = mutation;

      mutation.setOptions(options);

      mutation.execute(variables);
    }

    if (query) query.fetch();
  });

  isRefreshing = false;

  failedQueue = [];
};

const refreshTokenAndRetry = async (
  query?: Query<unknown, unknown>,
  mutation?: Mutation<unknown, unknown, unknown, unknown>,
  variables?: unknown
) => {
  try {
    if (!isRefreshing) {
      isRefreshing = true;

      failedQueue.push({ query, mutation, variables });

      if (isCapable(AppCapability.MainWindowExclusive)) {
        debugToast('access token expired, refreshing...');

        const { access_token, refresh_token = undefined } =
          await refreshAuthTokens(
            getLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
          );

        setLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);

        if (refresh_token)
          setLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token);

        debugToast('auth tokens refreshed, retrying failed requests...');
      } else debugToast('retrying failed requests...');

      await sleep(500);

      processFailedQueue();
    } else failedQueue.push({ query, mutation, variables });
  } catch {
    const authenticatedRoute = router.state.matches.some(
      (match) => match.id === '/_main'
    );
    if (isCapable(AppCapability.MainWindowExclusive) && authenticatedRoute)
      router.navigate({ to: '/error/session-expired' });
  }
};

const errorHandler = (
  error: unknown,
  query?: Query<unknown, unknown>,
  mutation?: Mutation<unknown, unknown, unknown, unknown>,
  variables?: unknown
) => {
  if (error instanceof Error) {
    const isAuthorizedRequest = error.message
      .toLowerCase()
      .includes('unauthorized request');

    if (!isAuthorizedRequest) return;

    if (mutation) refreshTokenAndRetry(undefined, mutation, variables);
    else refreshTokenAndRetry(query);
  }
};

export const queryErrorHandler = (
  error: DefaultError,
  query: Query<unknown, unknown>
) => {
  errorHandler(error, query);
};

export const mutationErrorHandler = (
  error: DefaultError,
  variables: unknown,
  _context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>
) => {
  errorHandler(error, undefined, mutation, variables);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchInterval: 0,
      staleTime: 0,
    },
    mutations: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: queryErrorHandler,
  }),
  mutationCache: new MutationCache({
    onError: mutationErrorHandler,
  }),
});
