import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { queryClient } from '@/query/client';
import { checkAccessibilityPermissionsQueryOptions } from '@/query/permissions';

export const Route = createFileRoute('/grant-permissions')({
  beforeLoad: async () => {
    const hasAccessibilityPermissions = await queryClient.ensureQueryData(
      checkAccessibilityPermissionsQueryOptions()
    );

    if (hasAccessibilityPermissions) {
      posthog.capture('redirect', {
        to: '/',
        from: '/grant-permissions',
      });
      throw redirect({ to: '/', replace: true });
    }
  },
});
