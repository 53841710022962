import { Outlet } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';

import Toast from './blocks/Toast';

import { useInternetConnectionCheck } from '@/hooks/useInternetConnectionCheck';
import { isAuthValidQueryAtom } from '@/stores/atoms/auth';

export const RouterRoot = () => {
  useInternetConnectionCheck();

  useAtomValue(isAuthValidQueryAtom);

  return (
    <>
      <Outlet />
      <Toast />
    </>
  );
};
