import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ChatServiceUserError, ChatServiceError } from '@/error';
import { queryClient } from '@/query/client';
import { fetchApiWithAuth } from '@/utils/fetch';

const ChatSchema = z.object({
  title: z.string(),
  chat_id: z.number(),
  chat_history: z.array(z.array(z.string())),
  date: z.string(),
  starred: z.boolean(),
});

export type Chat = z.infer<typeof ChatSchema>;

const CreateNewChatResponseSchema = z.object({
  chat_id: z.number(),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChatUpdateSchema = z.object({
  chat_id: z.number(),
  title: z.string().optional(),
  starred: z.boolean().optional(),
});

export type ChatUpdate = z.infer<typeof ChatUpdateSchema>;

export const chatsQueryOptions = () =>
  queryOptions({
    queryKey: ['chats'],
    queryFn: async () => {
      const response = await fetchApiWithAuth('chat_history');
      if (!response.ok) {
        throw new Error('failed to fetch chats');
      }
      const { chats } = await response.json();
      return z.array(ChatSchema).parse(chats);
    },
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 2,
  });

export const chatUpdateOptions = () => ({
  mutationKey: ['update-chat'],
  mutationFn: async (patch: ChatUpdate) => {
    queryClient.setQueryData(['chats'], (oldData: Chat[]) =>
      oldData
        ? oldData.map((chat: Chat) =>
            chat.chat_id === patch.chat_id
              ? {
                  ...chat,
                  ...(patch.title !== undefined && { title: patch.title }),
                  ...(patch.starred !== undefined && {
                    starred: patch.starred,
                  }),
                }
              : chat
          )
        : []
    );

    try {
      await fetchApiWithAuth(`update_chat/${patch.chat_id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...(patch.title !== undefined && { title: patch.title }),
          ...(patch.starred !== undefined && { starred: patch.starred }),
        }),
      });
    } catch (error) {
      queryClient.invalidateQueries({ queryKey: ['chats'] });
      throw error;
    }
  },
  onError: () => {
    queryClient.invalidateQueries({ queryKey: ['chats'] });
  },
});

export const chatDeleteOptions = () => ({
  mutationKey: ['delete-chat'],
  mutationFn: async (chat_id: number) => {
    queryClient.setQueryData(['chats'], (oldData: Chat[]) =>
      oldData ? oldData.filter((chat: Chat) => chat.chat_id !== chat_id) : []
    );

    try {
      await fetchApiWithAuth(`chat/${chat_id}`, {
        method: 'DELETE',
      });
    } catch (error) {
      queryClient.invalidateQueries({ queryKey: ['chats'] });
      throw error;
    }
  },
  onError: () => {
    queryClient.invalidateQueries({ queryKey: ['chats'] });
  },
});

export const chatCreateQueryOptions = () =>
  queryOptions({
    queryKey: ['create-chat'],
    queryFn: async () => {
      const response = await fetchApiWithAuth('new_chat', {
        method: 'POST',
      }).catch((error) => {
        if (String(error.message).toLowerCase() === 'failed to fetch') {
          throw new ChatServiceUserError(ChatServiceError.NetworkError);
        }
        if (String(error.message).toLowerCase() === 'load failed') {
          throw new ChatServiceUserError(ChatServiceError.InternalServerError);
        }
        throw new ChatServiceUserError(ChatServiceError.GeneralError);
      });

      if (!response.ok) throw new Error('unable to create new chat');

      const { chat_id } = CreateNewChatResponseSchema.parse(
        await response.json()
      );

      return chat_id;
    },
  });

export function useChats() {
  const { data: chats = [] } = useQuery(chatsQueryOptions());

  const updateMutation = useMutation(chatUpdateOptions());
  const deleteMutation = useMutation(chatDeleteOptions());

  return {
    chats,
    updateChat: updateMutation.mutate,
    deleteChat: deleteMutation.mutate,
  };
}

export function useRecentChats() {
  const { chats } = useChats();

  return [...chats]
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .filter((chat) => !chat.starred);
}
