import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { queryClient } from '@/query/client';
import { userQueryOptions } from '@/query/user';
import { isAuthenticated } from '@/utils/auth';

export const Route = createFileRoute('/personalize')({
  beforeLoad: () => {
    if (!isAuthenticated()) {
      posthog.capture('redirect', {
        to: '/login',
        from: '/personalize',
      });
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  loader: () => {
    const getUser = async () => {
      try {
        return await queryClient.ensureQueryData(userQueryOptions());
      } catch (_error) {
        return null;
      }
    };

    return {
      deferredUser: getUser(),
    };
  },
});
