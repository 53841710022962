import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { getLocalStorageItem } from '@/utils/localStorage';

const LAST_SKIPPED_VERSION = 'last_skipped_version';

export const lastSkippedVersionAtom = atomWithStorage<string | null>(
  LAST_SKIPPED_VERSION,
  getLocalStorageItem(LAST_SKIPPED_VERSION) ?? null
);

export const appUpdateAtom = atom((get) => ({
  lastSkippedVersion: get(lastSkippedVersionAtom),
}));
