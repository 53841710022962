import { API_URL } from './api';
import { getAuthHeader } from './auth';

import { IS_AUTH_VALID_QUERY_KEY } from '@/query/auth';
import { queryClient } from '@/query/client';

export async function fetchApiWithAuth(
  apiRoute: string,
  init?: Parameters<typeof fetch>[1]
) {
  const response = await fetch(`${API_URL}/${apiRoute}`, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
      ...getAuthHeader(),
    },
  });

  if (response.status === 401) {
    queryClient.invalidateQueries({
      queryKey: IS_AUTH_VALID_QUERY_KEY,
      refetchType: 'all',
    });

    throw new Error('Unauthorized request');
  }

  return response;
}
