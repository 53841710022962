import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { atomWithQuery } from 'jotai-tanstack-query';
import posthog from 'posthog-js';

import { isCollectingContextQueryOptions } from '@/query/context_collection';
import { FLAGS } from '@/stores/atoms/featureFlags';
import { getLocalStorageItem } from '@/utils/localStorage';

export const COLLECT_CONTEXT_LOCAL_STORAGE_KEY = 'collect-context';

export interface AppWindowFilter {
  appName?: string;
  windowTitle?: string;
  appNameContains?: string;
  windowTitleContains?: string;
  pairedCondition?: {
    appNameContains: string;
    windowTitleContains: string;
  };
}

export interface ContextCollectionSettings {
  shouldLoadDefaultPrivacyExclusions: boolean;
  includeAll: boolean;
  including: AppWindowFilter[];
  excluding: AppWindowFilter[];
  automaticallyRecordMeetings: boolean;
}

const DEFAULT_SETTINGS: ContextCollectionSettings = {
  shouldLoadDefaultPrivacyExclusions: true,
  includeAll: true,
  including: [],
  excluding: [],
  automaticallyRecordMeetings: false,
};

const getInitialSettings = (): ContextCollectionSettings => {
  const storedSettings = getLocalStorageItem('context-collection-settings');

  const meetingNotesEnabled =
    posthog.isFeatureEnabled(FLAGS.MEETING_NOTES) ?? false;

  return {
    ...DEFAULT_SETTINGS,
    automaticallyRecordMeetings:
      storedSettings?.automaticallyRecordMeetings ?? meetingNotesEnabled,
  };
};

export const contextCollectionSettingsAtom =
  atomWithStorage<ContextCollectionSettings>(
    'context-collection-settings',
    getInitialSettings()
  );

export const collectContextAtom = atomWithStorage<boolean>(
  COLLECT_CONTEXT_LOCAL_STORAGE_KEY,
  getLocalStorageItem(COLLECT_CONTEXT_LOCAL_STORAGE_KEY) ?? false
);

export const isCollectingContextQueryAtom = atomWithQuery(() =>
  isCollectingContextQueryOptions()
);

export const isCollectingContextAtom = atom(
  (get) => get(isCollectingContextQueryAtom).data ?? false
);
