import { getAxiosInstance } from '../axios';

export interface MeetingChunk {
  id: number;
  chunk_number: number;
  text: string;
  created_at: string;
}

export interface Meeting {
  id: number;
  created_at: string;
  ended_at: string | null;
  status: 'active' | 'ended';
  chunks: MeetingChunk[];
}

export async function start_meeting(): Promise<{ id: string }> {
  const response = await getAxiosInstance().post('/meetings', {});
  return response.data;
}

export async function upload_meeting_audio({
  id,
  chunkNumber,
  audio,
}: {
  id: string;
  chunkNumber: string;
  audio: Blob;
}): Promise<{ text: string }> {
  const formData = new FormData();
  formData.append('file', audio);

  const response = await getAxiosInstance().post(
    `/meetings/${id}/chunks/${chunkNumber}`,
    formData
  );
  return response.data;
}

export async function end_meeting({
  id,
}: {
  id: string;
}): Promise<{ status: string }> {
  const response = await getAxiosInstance().post(`/meetings/${id}/end`, {});
  return response.data;
}

export async function get_meetings(): Promise<Meeting[]> {
  const response = await getAxiosInstance().get('/meetings');
  return response.data;
}
