import { RouterProvider as TanstackRouterProvider } from '@tanstack/react-router';
import { useEffect } from 'react';

import { router } from '@/router';
import { AppCapability, isCapable } from '@/utils/capabilities';

export function RouterProvider() {
  // Hack for the Webkit backdrop bug.
  // See: https://discussions.apple.com/thread/255764118?sortBy=rank
  useEffect(() => {
    if (!isCapable(AppCapability.WebkitBackdropFilterFix)) return;
    const rootElement = document.getElementById('root');

    if (rootElement) rootElement.classList.add('bg-darkBGSolid');
  }, []);

  return <TanstackRouterProvider router={router} />;
}
