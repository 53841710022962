import { invoke } from '@tauri-apps/api/core';
import { listen, UnlistenFn } from '@tauri-apps/api/event';

import { CallbackResponse } from '@/types/dataclasses';

interface MeetingChunk {
  id: number;
  meetingId: number;
  chunkNumber: number;
  text: string;
}

interface Meeting {
  meetingId: number;
  fullTranscription: string;
  chunks?: MeetingChunk[];
}

export interface MeetingState {
  appName: string;
  product: string;
  isBrowser: boolean;
  currentUserIsMuted: boolean;
  attendeeNames: string[];
  currentSpeakers: string[];
  meetingId: string | null;
  meetingName: string | null;
  mutedAt: [number, number | null][]; // Array of [start, end] tuples where end can be null
  meetingDuration: number;
  status: 'active' | 'ended';
  transcription: string;
}

export async function startMeetingObserver() {
  const result = await invoke<string>('start_meeting_observer');
  return result;
}

export async function stopMeetingObserver() {
  const result = await invoke<string>('stop_meeting_observer');
  return result;
}

export async function startMeeting() {
  return new Promise<Meeting>(async (resolve, reject) => {
    let timeout: NodeJS.Timeout | null = null;
    let unlisten: UnlistenFn | null = null;

    try {
      unlisten = await listen<CallbackResponse<Meeting>>(
        'meeting-started',
        (event) => {
          const meeting = event.payload.payload;
          if (!meeting) {
            reject({
              status: 'ERROR',
              error: 'Meeting not found',
              code: 'NOT_FOUND',
            });
            return;
          }

          console.info('Meeting started with result:', meeting);
          resolve(meeting);

          // cleanup
          unlisten?.();
          if (timeout) clearTimeout(timeout);
        }
      );
      await invoke<string>('start_meeting');

      timeout = setTimeout(() => {
        unlisten?.();
        reject({
          status: 'ERROR',
          error: 'Timeout waiting for meeting-started event',
          code: 'TIMEOUT',
        });
      }, 10_000);
    } catch (error) {
      unlisten?.();
      reject({
        status: 'ERROR',
        error: typeof error === 'string' ? error : String(error),
        code: 'INVOKE_ERROR',
      });
    } finally {
    }
  });
}

export async function stopMeeting() {
  return new Promise<Meeting>(async (resolve, reject) => {
    let timeout: NodeJS.Timeout | null = null;
    let unlisten: UnlistenFn | null = null;

    try {
      unlisten = await listen<CallbackResponse<Meeting>>(
        'meeting-stopped',
        (event) => {
          console.info('Meeting stopped with result:', event);
          const response = event.payload;

          if (response.status === 'ERROR' && response.error) {
            reject({
              status: 'ERROR',
              error: response.error.message,
              code: response.error.code,
            });
            return;
          }

          if (!response.payload) {
            reject({
              status: 'ERROR',
              error: 'Meeting not found',
              code: 'NOT_FOUND',
            });
            return;
          }

          resolve(response.payload);

          // cleanup
          unlisten?.();
          if (timeout) clearTimeout(timeout);
        }
      );

      await invoke<string>('stop_meeting');

      timeout = setTimeout(() => {
        unlisten?.();
        reject({
          status: 'ERROR',
          error: 'Timeout waiting for meeting-stopped event',
          code: 'TIMEOUT',
        });
      }, 10_000);
    } catch (error) {
      unlisten?.();
      reject({
        status: 'ERROR',
        error: typeof error === 'string' ? error : String(error),
        code: 'INVOKE_ERROR',
      });
    }
  });
}

export async function onMeetingAppDetected(
  callback: (meetingState: MeetingState | null) => void
): Promise<UnlistenFn> {
  return await listen('meeting-app-detected', (event) => {
    console.info('meeting_app_detected', event.payload);
    callback(event.payload as MeetingState);
  });
}
