import { atom } from 'jotai';
import posthog from 'posthog-js';

import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage';

const FEATURE_FLAGS_KEY = 'featureFlags';
const FEATURE_FLAGS_OVERRIDE_KEY = 'featureFlagsOverride';

export const FLAGS = {
  EXPERIMENTAL_FEATURES: 'experimental-features',
  GROUNDHOG: 'groundhog-flag',
  STATE_AWARE_ONBOARDING: 'state-aware-onboarding',
  MEETING_NOTES: 'meeting-notes',
  INPUTS_V3: 'inputs-v3',
  HUMMINGBIRD_SPOTLIGHT: 'hummingbird-spotlight',
  EDITABLE_CONTENT_FILTERS: 'editable-content-filters',
} as const;

// See FeatureFlags.md for usage documentation

export const flagsLoadedAtom = atom<boolean>(false);

// New atom to store manual overrides
export const featureFlagsOverrideAtom = atom(
  getLocalStorageItem(FEATURE_FLAGS_OVERRIDE_KEY) || {},
  (_get, set, newOverrides: Record<string, boolean>) => {
    setLocalStorageItem(FEATURE_FLAGS_OVERRIDE_KEY, newOverrides);
    set(featureFlagsOverrideAtom, newOverrides);
  }
);

// Using this atom syncs posthog feature flags to local storage.
// Optionally, use posthog.isFeatureEnabled('your-flag-name') for js-only features.
export const featureFlagsAtom = atom((get) => {
  const flagsLoaded = get(flagsLoadedAtom);
  const localFlags = getLocalStorageItem(FEATURE_FLAGS_KEY) || {};
  const overrides = get(featureFlagsOverrideAtom);

  if (!flagsLoaded) {
    return { ...localFlags, ...overrides }; // Apply overrides to cached flags
  }

  const currentFlags = Object.fromEntries(
    Object.values(FLAGS).map((flagKey) => [
      flagKey,
      posthog.isFeatureEnabled(flagKey),
    ])
  );

  // Apply overrides to PostHog flags
  const finalFlags = { ...currentFlags, ...overrides };

  // Update localStorage with latest flags
  setLocalStorageItem(FEATURE_FLAGS_KEY, finalFlags);

  return finalFlags;
});
