import { useEffect } from 'react';

import { IS_AUTH_VALID_QUERY_KEY } from '@/query/auth';
import { queryClient } from '@/query/client';
import { listenWithAbort } from '@/utils/abort';
import { AUTH_TOKEN_REFRESH_INTERVAL, isAuthenticated } from '@/utils/auth';
import { AppCapability, isCapable } from '@/utils/capabilities';
import { debugToast } from '@/utils/toast';

export function useAuthTokensRefresh() {
  useEffect(() => {
    const controller = new AbortController();

    const intervalId = setInterval(() => {
      if (!isAuthenticated()) return;

      debugToast('refreshing auth tokens...');

      queryClient.invalidateQueries({
        queryKey: IS_AUTH_VALID_QUERY_KEY,
        refetchType: 'all',
      });
    }, AUTH_TOKEN_REFRESH_INTERVAL);

    if (isCapable(AppCapability.BackgroundAuthTokenRefresh)) {
      listenWithAbort(
        'auth_tokens_did_refresh',
        () =>
          queryClient.invalidateQueries({
            queryKey: IS_AUTH_VALID_QUERY_KEY,
            refetchType: 'all',
          }),
        controller.signal
      );
    }

    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, []);
}
