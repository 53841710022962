import {
  ChangeEvent,
  ElementRef,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import ChatIcon from '@/assets/icons/chat.svg?react';
import { Chat, useRecentChats } from '@/query/chat';
import { useAppStore } from '@/store';
import { classList } from '@/utils/cn';

export const NEW_CHAT_TITLE = 'New Chat';

export interface ChatTitleProps {
  chatId: Chat['chat_id'];
}

export function ChatTitle({ chatId }: ChatTitleProps) {
  const recentChats = useRecentChats();

  const { focusedChat, setFocusedChat } = useAppStore();

  const measureTitleRef = useRef<ElementRef<'span'>>(null);

  const selectRef = useRef<ElementRef<'select'>>(null);

  const value = focusedChat?.chat_id ?? chatId;

  const title = useMemo(
    () =>
      recentChats.find(({ chat_id: some_chat_id }) => some_chat_id === value)
        ?.title ?? NEW_CHAT_TITLE,
    [recentChats, value]
  );

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedChatId = parseInt(event.target.value);

    const some_chat = recentChats.find(
      ({ chat_id: some_chat_id }) => selectedChatId === some_chat_id
    );

    setFocusedChat(some_chat ?? null);
  };

  const handleOnContextMenu = (event: ReactMouseEvent<HTMLSelectElement>) => {
    event.preventDefault();

    if (selectRef.current) {
      const element = selectRef.current;

      element.focus();

      const clickEvent = new MouseEvent('mousedown');

      element.dispatchEvent(clickEvent);
    }
  };

  useEffect(() => {
    if (measureTitleRef.current && selectRef.current) {
      const { width } = measureTitleRef.current.getBoundingClientRect();

      selectRef.current.style.setProperty('--select-width', `${width + 8}px`);
    }
  }, [selectRef, measureTitleRef, focusedChat, recentChats, chatId]);

  return (
    <div className="relative m-auto flex items-center justify-center gap-2">
      <span
        ref={measureTitleRef}
        className="absolute touch-none pointer-events-none whitespace-nowrap opacity-0"
      >
        {title}
      </span>

      <div>
        <ChatIcon className="text-md transform-gpu" />
      </div>

      <select
        ref={selectRef}
        className={classList(
          'appearance-none bg-transparent border-none',
          'focus:outline-none focus:ring-0',
          'w-[var(--select-width,auto)]',
          'truncate'
        )}
        name="chats"
        defaultValue={chatId}
        value={value}
        onChange={handleOnChange}
        onContextMenu={handleOnContextMenu}
      >
        {recentChats.length > 0 && (
          <option key={-1} value={-1}>
            {NEW_CHAT_TITLE}
          </option>
        )}

        {recentChats.map(
          ({ chat_id: recent_chat_id, title: recent_chat_title }) => (
            <option key={recent_chat_id} value={recent_chat_id}>
              {recent_chat_title}
            </option>
          )
        )}
      </select>
    </div>
  );
}
