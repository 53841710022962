import { invoke } from '@tauri-apps/api/core';
import { atomWithStorage } from 'jotai/utils';

import store from '../jotaiStore';

import { featureFlagsAtom, FLAGS } from './featureFlags';

import {
  AppCapability,
  inBrowser,
  inMobile,
  isCapable,
} from '@/utils/capabilities';
import { getLocalStorageItem } from '@/utils/localStorage';

// Keyboard shortcut settings
export const COMMAND_CALLBACK_ENABLED_LOCAL_STORAGE_KEY =
  'keyboard-shortcut-enabled';
export const MODIFIER_KEY_LOCAL_STORAGE_KEY = 'keyboard-shortcut-modifier';
export const TAP_COUNT_LOCAL_STORAGE_KEY = 'keyboard-shortcut-tap-count';
export const TAP_INTERVAL_LOCAL_STORAGE_KEY = 'keyboard-shortcut-tap-interval';

export const commandCallbackEnabledAtom = atomWithStorage<boolean>(
  COMMAND_CALLBACK_ENABLED_LOCAL_STORAGE_KEY,
  getLocalStorageItem(COMMAND_CALLBACK_ENABLED_LOCAL_STORAGE_KEY) ?? true
);

export const modifierKeyAtom = atomWithStorage<string>(
  MODIFIER_KEY_LOCAL_STORAGE_KEY,
  getLocalStorageItem(MODIFIER_KEY_LOCAL_STORAGE_KEY) ?? 'command'
);

export const tapCountAtom = atomWithStorage<number>(
  TAP_COUNT_LOCAL_STORAGE_KEY,
  getLocalStorageItem(TAP_COUNT_LOCAL_STORAGE_KEY) ?? 3
);

export const tapIntervalAtom = atomWithStorage<number>(
  TAP_INTERVAL_LOCAL_STORAGE_KEY,
  getLocalStorageItem(TAP_INTERVAL_LOCAL_STORAGE_KEY) ?? 0.5
);

// Subscribe to changes and update the key monitor configuration
if (isCapable(AppCapability.HummingBirdCommandShortcut)) {
  store.sub(commandCallbackEnabledAtom, async () => {
    const enabled = store.get(commandCallbackEnabledAtom);
    const featureFlags = store.get(featureFlagsAtom);
    const experimentalEnabled = featureFlags[FLAGS.EXPERIMENTAL_FEATURES];

    if (enabled && experimentalEnabled) {
      const modifier = store.get(modifierKeyAtom);
      const taps = store.get(tapCountAtom);
      const interval = store.get(tapIntervalAtom);

      await invoke('register_command_callback', {
        modifier,
        taps,
        interval,
      });
    } else {
      await invoke('disable_command_callback');
    }
  });

  store.sub(featureFlagsAtom, async () => {
    const enabled = store.get(commandCallbackEnabledAtom);
    const featureFlags = store.get(featureFlagsAtom);
    const experimentalEnabled = featureFlags[FLAGS.EXPERIMENTAL_FEATURES];

    if (enabled && experimentalEnabled) {
      await invoke('register_command_callback', {
        modifier: store.get(modifierKeyAtom),
        taps: store.get(tapCountAtom),
        interval: store.get(tapIntervalAtom),
      });
    } else {
      await invoke('disable_command_callback');
    }
  });

  // Subscribe to individual setting changes to update configuration when enabled
  const updateKeyMonitorConfig = async () => {
    const enabled = store.get(commandCallbackEnabledAtom);
    const featureFlags = store.get(featureFlagsAtom);
    const experimentalEnabled = featureFlags[FLAGS.EXPERIMENTAL_FEATURES];

    if (enabled && experimentalEnabled) {
      await invoke('register_command_callback', {
        modifier: store.get(modifierKeyAtom),
        taps: store.get(tapCountAtom),
        interval: store.get(tapIntervalAtom),
      });
    }
  };

  store.sub(modifierKeyAtom, updateKeyMonitorConfig);
  store.sub(tapCountAtom, updateKeyMonitorConfig);
  store.sub(tapIntervalAtom, updateKeyMonitorConfig);
}

async function setFeatureFlags(featureFlags: any) {
  await invoke('set_feature_flags_state', {
    flags: JSON.stringify(featureFlags),
  });
}

if (!inBrowser() && !inMobile()) {
  let featureFlags = store.get(featureFlagsAtom);
  setFeatureFlags(featureFlags);
  store.sub(featureFlagsAtom, async () => {
    featureFlags = store.get(featureFlagsAtom);
    await invoke('set_feature_flags_state', {
      flags: JSON.stringify(featureFlags),
    });
  });
}
