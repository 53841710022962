import { queryOptions } from '@tanstack/react-query';
import { z } from 'zod';

import { fetchApiWithAuth } from '@/utils/fetch';

const OnboardingSchema = z.object({
  onboarding_progress: z.number(),
});

export type Onboarding = z.infer<typeof OnboardingSchema>;

export const onboardingQueryOptions = () =>
  queryOptions({
    queryKey: ['onboarding'],
    retry: 2,
    queryFn: async () => {
      const response = await fetchApiWithAuth('onboarding_progress');

      if (!response.ok) {
        throw new Error('failed to fetch onboarding progress');
      }

      const data = await response.json();

      return OnboardingSchema.parse(data);
    },
  });

export const onboardingMutationOptions = () => ({
  mutationKey: ['update-onboarding'],
  mutationFn: async (data: Partial<Onboarding>) => {
    await fetchApiWithAuth('onboarding_progress', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },
});
