import { EventCallback, EventName, listen } from '@tauri-apps/api/event';

export async function listenWithAbort<T>(
  name: EventName,
  callback: EventCallback<T>,
  signal: AbortSignal
) {
  const unlisten = listen
    ? await listen<T>(name, (...params) => {
        if (signal?.aborted) {
          unlisten?.();

          return;
        }

        callback(...params);
      })
    : undefined;

  if (signal?.aborted) {
    unlisten?.();

    return;
  }

  signal?.addEventListener('abort', () => {
    unlisten?.();
  });
}
