import { getVersion } from '@tauri-apps/api/app';
import posthog from 'posthog-js';

import { inBrowser, inMobile } from './capabilities';
import { getLocalStorageItem } from './localStorage';

import { COLLECT_CONTEXT_LOCAL_STORAGE_KEY } from '@/stores/atoms/context_collection';
import { COMMAND_CALLBACK_ENABLED_LOCAL_STORAGE_KEY } from '@/stores/atoms/settings';

export function collect_user_config() {
  const localStorageKeys = [
    COLLECT_CONTEXT_LOCAL_STORAGE_KEY,
    COMMAND_CALLBACK_ENABLED_LOCAL_STORAGE_KEY,
  ];

  for (const key of localStorageKeys) {
    posthog.capture('$set', {
      $set: {
        [`config_${key}`]: getLocalStorageItem(key),
      },
    });
  }
}

export async function identify_person(email: string) {
  let appVersion = 'web';
  if (!inBrowser()) {
    appVersion = await getVersion();
  }

  if (inMobile()) {
    posthog.capture('$set', {
      $set: { appVersion },
    });
    return;
  }

  posthog.identify(email, {
    email,
    appVersion,
  });
}
