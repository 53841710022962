import { useEffect } from 'react';

import { Chat } from '@/query/chat';
import { useAppStore } from '@/store';
import { listenWithAbort } from '@/utils/abort';
import { isCapable, AppCapability } from '@/utils/capabilities';

export function useFocusedChatListener() {
  const { setFocusedChat } = useAppStore();
  const enableFocusChatListener = isCapable(AppCapability.FocusChatListener);

  useEffect(() => {
    if (!enableFocusChatListener) return;
    const controller = new AbortController();
    listenWithAbort<Chat>(
      'SET_FOCUSED_CHAT',
      ({ payload }) => setFocusedChat(payload),
      controller.signal
    );
    return () => controller.abort();
  }, [enableFocusChatListener, setFocusedChat]);
}
