import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { userAtom } from './user';

import { inMobile } from '@/utils/capabilities';
import { getLocalStorageItem } from '@/utils/localStorage';

const CHAT_MODE_LOCAL_STORAGE_KEY = 'chat-mode';
const MAIN_CHAT_MODE = 'agentic';

export const storageChatModeAtom = atomWithStorage<string>(
  CHAT_MODE_LOCAL_STORAGE_KEY,
  getLocalStorageItem(CHAT_MODE_LOCAL_STORAGE_KEY) ?? MAIN_CHAT_MODE
);

// Enforce that chatMode can never be anything other than MAIN_CHAT_MODE or one of user's allowed chat modes
export const chatModeAtom = atom(
  (get) => {
    const mode = get(storageChatModeAtom);
    const user = get(userAtom);

    if (user?.allowed_chat_modes?.includes(mode)) {
      return mode;
    }
    return MAIN_CHAT_MODE;
  },
  (get, set, newMode: string) => {
    const user = get(userAtom);
    if (user?.allowed_chat_modes?.includes(newMode)) {
      set(storageChatModeAtom, newMode);
    }
  }
);

export const chatInputRefAtom = atom<HTMLTextAreaElement | null>(null);

export const focusChatInputAtom = atom((get) => {
  const textareaRef = get(chatInputRefAtom);
  return () => {
    if (textareaRef && !inMobile()) {
      textareaRef.focus();
    }
  };
});
