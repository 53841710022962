import { fetchEventSource } from '@microsoft/fetch-event-source';
import { invoke } from '@tauri-apps/api/core';

import { Todo } from '../types/dataclasses';

import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, decodeIdToken } from './auth';
import { getAxiosInstance } from './axios';
import { AppCapability, isCapable } from './capabilities';
import { getLocalStorageItem } from './localStorage';

import { getEnvVar } from '@/env';
import {
  TokenExpiredError,
  ChatServiceUserError,
  ChatServiceError,
} from '@/error';
import { logError } from '@/lib/errorLogging';
import { IS_AUTH_VALID_QUERY_KEY } from '@/query/auth';
import { Chat } from '@/query/chat';
import { queryClient } from '@/query/client';

export * from './api/meetingNotes';

export const API_URL = getEnvVar('VITE_API_URL');

async function get_common_auth_body(email: string) {
  return {
    client_id: getEnvVar('VITE_AUTH0_CLIENT_ID'),
    username: email,
    audience: getEnvVar('VITE_AUTH0_AUDIENCE'),
    scope: 'openid profile email offline_access',
    device: isCapable(AppCapability.BackgroundAuthTokenRefresh)
      ? await invoke('get_device_id')
      : undefined,
  };
}

const build_email_password_body = (password: string) => ({
  grant_type: 'password',
  password,
  realm: 'Username-Password-Authentication', // Database realm
  connection: 'Username-Password-Authentication',
});

const build_passwordless_body = (authCode: string) => ({
  grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
  otp: authCode,
  realm: 'email',
  connection: 'email',
});

async function exchange(body: {
  grant_type: string;
  otp?: string;
  password?: string;
  realm: string;
  client_id: string;
  username: string;
  audience: string;
  scope: string;
  device: unknown;
}) {
  const response = await fetch(
    `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/oauth/token`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(
      `Token exchange failed: ${errorResponse.error_description}`
    );
  }

  const res = await response.json();
  const userInfo = decodeIdToken(res.id_token);

  return {
    refresh_token: res.refresh_token,
    access_token: res.access_token,
    expires_in: Date.now() + res.expires_in * 1000,
    email: userInfo.email,
  };
}

export async function exchange_code_for_token(authCode: string, email: string) {
  const body = {
    ...(await get_common_auth_body(email)),
    ...build_passwordless_body(authCode),
  };

  return await exchange(body);
}

export async function exchange_password_for_token(
  email: string,
  password: string
) {
  const body = {
    ...(await get_common_auth_body(email)),
    ...build_email_password_body(password),
  };

  return await exchange(body);
}

export async function sync_user(access_token: string) {
  const response = await getAxiosInstance().post(
    '/sync/user',
    {},
    {
      // AS THIS RUNS INITIALLY WHEN LOGGING IN, ACCESS TOKEN IS NOT YET SET, SET TOKEN MANUALLY
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
    }
  );
  return response.data;
}

export async function delete_user_context() {
  await getAxiosInstance().post('/delete_user_context');
}

export async function get_shared_chat(
  chatId: string,
  chatPublicToken: string
): Promise<Chat> {
  const response = await getAxiosInstance().get(`/get_shared_chat/${chatId}`, {
    params: { public_token: chatPublicToken },
  });
  return response.data;
}

export async function set_chat_as_public(
  chatId: number
): Promise<{ public_token: string }> {
  const response = await getAxiosInstance().patch(
    `/set_chat_as_public/${chatId}`
  );
  return response.data;
}

export async function get_chat_reply(
  question: string,
  chatId: number,
  chatMode: string,
  attachments: File[],
  onMessage: (data: unknown) => void,
  context?: string
) {
  const formData = new FormData();

  if (attachments.length) {
    attachments.forEach((attachment) => {
      formData.append('files', attachment);
    });
  }

  formData.append('question', question);

  formData.append('chat_id', chatId.toString());

  formData.append('mode', chatMode);

  if (context) {
    formData.append('context', context);
  }

  const chatApiVersion = chatMode === 'fast' ? 'v3' : 'v4';

  const controller = new AbortController();
  const ON_MESSAGE_TIMEOUT = 1000 * 60 * 1;
  let onMessageTimeout: NodeJS.Timeout | undefined;

  try {
    await fetchEventSource(`${API_URL}/chat_${chatApiVersion}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)}`,
      },
      signal: controller.signal,
      openWhenHidden: true,
      onmessage(message) {
        try {
          const response = JSON.parse(message.data);
          onMessage(response);

          // Reset the timeout timer only if the message was parsed successfully
          // This prevents a case where the server stalls but keeps sending empty messages
          if (onMessageTimeout) {
            clearTimeout(onMessageTimeout);
          }

          onMessageTimeout = setTimeout(() => {
            controller.abort(
              new ChatServiceUserError(ChatServiceError.RequestTimedOut)
            );
          }, ON_MESSAGE_TIMEOUT);
        } catch (_error) {
          logError(_error, 'error parsing server sent event message:', {
            context: 'get_chat_reply',
            metadata: {
              message: message.data,
            },
          });
        }
      },
      async onopen(response) {
        if (response.status === 401) {
          queryClient.invalidateQueries({
            queryKey: IS_AUTH_VALID_QUERY_KEY,
            refetchType: 'all',
          });

          throw new TokenExpiredError(
            'invalid or expired authentication token provided'
          );
        }

        if (
          response.ok &&
          response.headers.get('content-type')?.includes('text/event-stream')
        ) {
          return;
        }
        throw new ChatServiceUserError(ChatServiceError.NetworkError);
      },
      onerror(error) {
        if (String(error.message).toLowerCase() === 'failed to fetch') {
          throw new ChatServiceUserError(ChatServiceError.NetworkError);
        }
        throw new ChatServiceUserError(ChatServiceError.InternalServerError);
      },
    });
  } finally {
    if (onMessageTimeout) {
      clearTimeout(onMessageTimeout);
    }
    if (!controller.signal.aborted) {
      controller.abort();
    }
  }
}

export async function stop_chat_with_context(
  chatId: number,
  last_message?: string[]
) {
  await getAxiosInstance().post(`/stop_chat_with_context/${chatId}`, {
    last_message,
  });
}

export async function speech_to_text(audio: Blob): Promise<string> {
  const formData = new FormData();
  formData.append('file', audio, 'recording.wav');

  const response = await getAxiosInstance().post('/stt', formData);
  return response.data.text;
}

export async function text_to_speech(text: string): Promise<string> {
  const response = await getAxiosInstance().post(
    '/tts',
    { text },
    { responseType: 'blob' }
  );

  const blob = new Blob([response.data], { type: 'audio/mpeg' });
  return URL.createObjectURL(blob);
}

export async function delete_inputs(timestamp: number) {
  await getAxiosInstance().post('/delete/inputs', { timestamp });
}

export type AddInputsPayload = {
  collected: {
    [appName: string]:
      | {
          text: string[];
          metadata?: {
            collector?: string;
          };
        }
      | {
          text: string;
          metadata?: {
            collector?: string;
          };
        }[];
  };
};
export type AddInputsResponse = {
  detail: string;
};
export async function add_inputs(
  payload: AddInputsPayload
): Promise<AddInputsResponse> {
  const response = await getAxiosInstance().post('/add/inputs/v2', payload);
  return response.data;
}

export async function get_personal_access_token(): Promise<string> {
  const response = await getAxiosInstance().get('/personal_access_token');
  return response.data.access_token;
}

export async function get_message_suggestions(
  app: string,
  context?: string,
  focusedInputDescription?: string,
  focusedInputValue?: string
): Promise<string[]> {
  const response = await getAxiosInstance().post(
    '/generate_hummingbird_suggestions',
    {
      app,
      current_context: context,
      focused_input_description: focusedInputDescription,
      focused_input_value: focusedInputValue,
    }
  );
  return response.data.suggestions;
}

export async function generate_hummingbird_todo(
  app: string,
  currentContext: string,
  originalTodo?: Todo,
  modificationPrompt?: string
): Promise<{
  todo: Todo;
}> {
  const response = await getAxiosInstance().post('/generate_hummingbird_todo', {
    app,
    current_context: currentContext,
    original_todo: originalTodo,
    modification_prompt: modificationPrompt,
  });
  return response.data;
}

export async function get_action_suggestions(
  app: string,
  windowTitle: string,
  selectedText?: string
): Promise<string[]> {
  const response = await getAxiosInstance().post(
    '/generate_hummingbird_action_suggestions',
    {
      app,
      window_title: windowTitle,
      selected_text: selectedText,
    }
  );
  return response.data.suggestions;
}

export async function generate_hummingbird_detailed_message(
  app: string,
  windowTitle: string,
  selectedText?: string,
  originalResponse?: string,
  modificationPrompt?: string,
  currentContext?: string
): Promise<string> {
  const response = await getAxiosInstance().post(
    '/generate_hummingbird_detailed_message',
    {
      app,
      window_title: windowTitle,
      selected_text: selectedText,
      original_response: originalResponse,
      modification_prompt: modificationPrompt,
      current_context: currentContext,
    }
  );
  return response.data.response;
}
