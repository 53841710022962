import { useAtomValue } from 'jotai';

import { UnableToConnectToServerError } from '@/error';
import { isConnectedAtom } from '@/stores/atoms/connection';
import { AppCapability, isCapable } from '@/utils/capabilities';

export function useInternetConnectionCheck() {
  const isConnected = useAtomValue(isConnectedAtom);

  if (!isConnected && isCapable(AppCapability.MainWindowExclusive)) {
    throw new UnableToConnectToServerError();
  }
}
