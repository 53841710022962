import { useRouter, useLocation } from '@tanstack/react-router';

import Logo from '../assets/icons/logo.svg';

import Button from '@/components/ui/Button';
import CenteredTile from '@/components/ui/CenteredTile';
import { useUpdate } from '@/providers/UpdateProvider';
import { handleMailTo } from '@/utils/platform';

function UpdateScreen() {
  const router = useRouter();
  const location = useLocation();
  const {
    downloadProgress,
    runUpdate,
    updateAvailable,
    skipUpdate,
    isUpdateCritical,
  } = useUpdate();

  return (
    <CenteredTile
      title="Update to the latest version"
      leadingContent={<img src={Logo} alt="Little Bird" className="mx-auto" />}
      description={
        updateAvailable?.version
          ? 'V ' + updateAvailable?.version + ' is now available for download'
          : 'A new version is available for download'
      }
      content={
        <>
          <div className="gap-4 flex flex-col mb-6">
            <Button
              className="w-full "
              type="button"
              disabled={downloadProgress !== null}
              onPress={() => {
                runUpdate();
              }}
            >
              {downloadProgress !== null ? 'Downloading...' : 'Update'}
            </Button>
            {!downloadProgress && !isUpdateCritical && (
              <button
                onClick={() => {
                  skipUpdate();
                  router.navigate({
                    to:
                      new URLSearchParams(location.search).get('redirect') ||
                      '/',
                    replace: true,
                  });
                }}
                className="flex items-center justify-center  opacity-90 hover:opacity-100  text-xs font-mono whitespace-nowrap uppercase text-darkPrimary"
              >
                Skip
              </button>
            )}
          </div>
          {downloadProgress !== null && (
            <div className="my-4">
              <div
                className="flex w-full h-2 bg-darkLine rounded-full overflow-hidden dark:bg-neutral-700"
                role="progressbar"
                aria-valuenow={downloadProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="flex flex-col justify-center rounded-full overflow-hidden bg-cta-gradient text-xs text-white text-center whitespace-nowrap transition duration-500 "
                  style={{ width: `${downloadProgress}%` }}
                />
              </div>
            </div>
          )}

          <p className="mt-2 text-center text-darkSecondary text-sm">
            Trouble logging in?{' '}
            <span
              className="text-darkPrimary cursor-pointer underline"
              onClick={() => {
                handleMailTo(
                  'support@genos.dev',
                  'I need assistance',
                  'I cant login'
                );
              }}
            >
              Contact Support
            </span>
          </p>
        </>
      }
    />
  );
}

export default UpdateScreen;
