import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

type IconButtonIconSize = '15px' | '13px' | '18px';

interface IconButtonProps {
  icon: IconDefinition;
  onClick?: React.MouseEventHandler;
  hoverIcon?: IconDefinition;
  disabled?: boolean;
  className?: string;
  size?: IconButtonIconSize;
  iconProps?: Partial<FontAwesomeIconProps>;
  padded?: boolean;
  title?: string;
}

export default function IconButton({
  icon,
  onClick,
  hoverIcon,
  size = '15px',
  disabled = false,
  className = '',
  iconProps,
  padded = false,
  title,
}: IconButtonProps) {
  const paddedSizeCl = !padded
    ? ' '
    : `hover:bg-darkFieldHover hover:rounded-md ${
        size === '18px' ? 'w-10 h-10' : size === '15px' ? 'w-8 h-8' : 'w-7 h-7'
      }`;

  return (
    <motion.button
      whileTap={disabled ? undefined : { scale: 0.8 }}
      style={{ originX: '50%', originY: '50%' }}
      className={`disabled:opacity-80 group relative ${className} ${
        paddedSizeCl
      }`}
      onClick={onClick ?? undefined}
      disabled={disabled}
      title={title}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{ fontSize: size }}
        className={`
          duration-75
          transition-colors
          text-darkSecondary
          ${!disabled && 'group-hover:text-darkPrimary'}
          ${hoverIcon && 'group-hover:hidden'}
          ${iconProps?.className ?? ''}
        `.trim()}
        {...iconProps}
      />
      {hoverIcon && (
        <FontAwesomeIcon
          icon={hoverIcon}
          style={{ fontSize: size }}
          className={`
            duration-75
            transition-colors
            text-darkSecondary
            ${!disabled && 'group-hover:text-darkPrimary'}
            hidden group-hover:block
            ${iconProps?.className ?? ''}
          `.trim()}
          {...iconProps}
        />
      )}
    </motion.button>
  );
}
