import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { isAuthenticated } from '@/utils/auth';

export const Route = createFileRoute('/login')({
  beforeLoad: ({ location }) => {
    if (isAuthenticated()) {
      posthog.capture('redirect', {
        to: '/',
        from: '/login',
      });
      throw redirect({
        to: new URLSearchParams(location.search).get('redirect') || '/',
        replace: true,
      });
    }
  },
});
