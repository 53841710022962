import { invoke } from '@tauri-apps/api/core';
import posthog from 'posthog-js';

import { API_URL } from './api';
import {
  startMeetingObserver,
  stopMeetingObserver,
} from './native/meetingNotes';

import { contextCollectionSettingsAtom } from '@/stores/atoms/context_collection';
import jotaiStore from '@/stores/jotaiStore';
import { AppCapability, isCapable } from '@/utils/capabilities';

export type StartContextCollectionOptions = {
  accessToken: string;
  email: string;
  experimentalFeaturesEnabled: boolean;
};

export async function startContextCollection(
  { accessToken, email }: StartContextCollectionOptions,
  timeout: number = 1000 * 60 * 1
) {
  const supportsContextCollection = isCapable(AppCapability.ContextCapture);
  if (!supportsContextCollection) return;

  const isAccessibilityPermissionsGranted = await invoke<boolean>(
    'check_accessibility_permissions'
  );

  if (!isAccessibilityPermissionsGranted)
    throw new Error('accessibility permissions are not granted');

  await invoke('start_context_collection', {
    token: accessToken,
    email,
    url: API_URL,
  });

  const settings = jotaiStore.get(contextCollectionSettingsAtom);

  if (settings.automaticallyRecordMeetings) {
    await startMeetingObserver();
  }

  const controller = new AbortController();

  return Promise.race([
    new Promise<void>((resolve) => {
      const intervalTimer = setInterval(
        () =>
          invoke<boolean>('context_collection_working').then((isWorking) => {
            if (isWorking) {
              controller.abort();

              invoke('update_context_collection_state', {
                status: true,
              });

              posthog.capture('user_enabled_context_collection');

              resolve();
            }
          }),
        250
      );

      controller.signal.addEventListener('abort', () => {
        clearInterval(intervalTimer);
      });
    }),

    new Promise<void>((_, reject) => {
      setTimeout(
        (error) => {
          controller.abort(error);

          reject(error);
        },
        timeout,
        new Error('starting context collection timed out')
      );
    }),
  ]);
}

export async function stopContextCollection(timeout: number = 1000 * 60 * 10) {
  const supportsContextCollection = isCapable(AppCapability.ContextCapture);
  if (!supportsContextCollection) return;

  await invoke('stop_context_collection');

  // Always stop the meeting observer when stopping context collection
  await stopMeetingObserver();

  const controller = new AbortController();

  return Promise.race([
    new Promise<void>((resolve) => {
      const intervalTimer = setInterval(
        () =>
          invoke<boolean>('context_collection_working').then((isWorking) => {
            if (!isWorking) {
              controller.abort();

              invoke('update_context_collection_state', {
                status: false,
              });

              posthog.capture('user_disabled_context_collection');

              resolve();
            }
          }),
        250
      );

      controller.signal.addEventListener('abort', () => {
        clearInterval(intervalTimer);
      });
    }),
    new Promise<void>((_, reject) => {
      setTimeout(
        (error) => {
          controller.abort(error);

          reject(error);
        },
        timeout,
        new Error('context collection did not stop after timeout')
      );
    }),
  ]);
}
