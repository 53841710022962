interface RetryOptions {
  maxAttempts?: number;
  delay?: number;
  timeout?: number;
}

export async function retry<T>(task: () => Promise<T>, options?: RetryOptions) {
  const { maxAttempts = 3, delay = 1000, timeout } = options ?? {};

  let lastError = new Error();

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      if (!timeout) return await task();

      return Promise.race([
        task(),
        new Promise<T>((_, reject) =>
          setTimeout(reject, timeout, new Error('task timed out'))
        ),
      ]);
    } catch (error) {
      lastError = error as Error;

      if (attempt === maxAttempts) break;

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  return Promise.reject(lastError);
}

interface RetryWithBackoffOptions {
  initialDelay?: number;
  maxAttempts?: number;
  timeout?: number;
}

export async function retryWithBackoff<T>(
  task: () => Promise<T>,
  options?: RetryWithBackoffOptions
): Promise<T> {
  const {
    maxAttempts = 5,
    initialDelay = 1000 * 60 * 0.1,
    timeout,
  } = options ?? {};

  let lastError = new Error();

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const taskPromise = task();
      if (!timeout) {
        return await taskPromise;
      }

      return await Promise.race([
        taskPromise,
        new Promise<T>((_, reject) =>
          setTimeout(() => reject(new Error('task timed out')), timeout)
        ),
      ]);
    } catch (error) {
      lastError = error as Error;

      if (attempt === maxAttempts) {
        break;
      }

      const backoffDelay = initialDelay * Math.pow(2, attempt - 1);
      await new Promise((resolve) => setTimeout(resolve, backoffDelay));
    }
  }

  return Promise.reject(lastError);
}
