export enum ChatServiceError {
  GeneralError = 'GENERAL_ERROR',
  RequestTimedOut = 'REQUEST_TIMED_OUT',
  NetworkError = 'NETWORK_ERROR',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
}

export const ChatErrorToUserMessageMap = {
  [ChatServiceError.GeneralError]:
    "Oops! Something went wrong on our end - we're looking into it.",
  [ChatServiceError.RequestTimedOut]:
    "Hmm, it seems like the request took too long. Let's try that again!",
  [ChatServiceError.NetworkError]:
    "I'm having trouble connecting to my servers at the moment - sorry about that!",
  [ChatServiceError.InternalServerError]:
    "There was an error on our side. We're on it!",
};

export class ChatServiceUserError extends Error {
  status: number | undefined;
  errorType: ChatServiceError;
  constructor(
    public readonly error: ChatServiceError,
    message?: string,
    status?: number
  ) {
    super(message || ChatErrorToUserMessageMap[error]);
    this.name = 'ChatServiceUserError';
    this.status = status;
    this.errorType = error;
  }
}

export class TokenExpiredError extends Error {
  constructor(message?: string) {
    super(message);

    this.name = 'TokenExpiredError';
  }
}

export class UnableToConnectToServerError extends Error {
  constructor(message?: string) {
    super(message);

    this.name = 'UnableToConnectToServerError';
  }
}
