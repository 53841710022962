import { queryOptions } from '@tanstack/react-query';

import {
  CHECK_CONNECTION_REFETCH_INTERVAL,
  checkConnection,
} from '@/utils/connection';

export const IS_CONNECTED_QUERY_KEY = ['is-connected'];

export const isConnectedQueryOptions = () =>
  queryOptions({
    queryKey: IS_CONNECTED_QUERY_KEY,
    retry: false,
    refetchInterval: CHECK_CONNECTION_REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    queryFn: async () => checkConnection(),
  });
