export const CHECK_CONNECTION_REFETCH_INTERVAL = 1000 * 60 * 0.5;

export async function checkConnection() {
  try {
    await fetch('https://www.google.com/favicon.ico', {
      mode: 'no-cors',
    });
    return true;
  } catch {
    return false;
  }
}
