import { ReactNode } from '@tanstack/react-router';
import { getCurrentWindow } from '@tauri-apps/api/window';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import store from '../jotaiStore';

import { inBrowser, inMobile } from '@/utils/capabilities';
export type WindowTitle = {
  title?: string | ReactNode;
  altTitle: string;
};

export const windowTitleAtom = atom<WindowTitle>({
  altTitle: 'New Chat',
});

export const useWindowTitle = () => useAtomValue(windowTitleAtom);

export const useSetWindowTitle = () => useSetAtom(windowTitleAtom);

store.sub(windowTitleAtom, () => {
  if (inBrowser() || inMobile()) return;
  const { altTitle } = store.get(windowTitleAtom);
  getCurrentWindow().setTitle(altTitle);
});
