import { atom } from 'jotai';
import { RESET } from 'jotai/utils';
import { atomWithMutation, atomWithQuery } from 'jotai-tanstack-query';

import { queryClient } from '@/query/client';
import {
  userMutationOptions,
  User,
  USER_QUERY_KEY,
  userQueryOptions,
} from '@/query/user';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage';

const userQueryAtom = atomWithQuery(() => userQueryOptions());
const userMutationAtom = atomWithMutation(() => userMutationOptions());

export const userAtom = atom(
  (get) => {
    const userData = get(userQueryAtom);
    return {
      ...getLocalStorageItem('user'),
      ...userData,
    } as User;
  },
  (get, _set, value: Partial<User> | typeof RESET) => {
    if (value === RESET) {
      localStorage.removeItem('user');

      queryClient.removeQueries({ queryKey: USER_QUERY_KEY });

      return;
    }

    setLocalStorageItem('user', value);

    queryClient.setQueryData(USER_QUERY_KEY, value as User);

    get(userMutationAtom).mutate(value, {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY }),
    });
  }
);
