import { captureException } from '@sentry/react';
import { invoke } from '@tauri-apps/api/core';
import { debug } from '@tauri-apps/plugin-log';
import { check, Update } from '@tauri-apps/plugin-updater';
import { millisecondsInMinute } from 'date-fns/constants';
import { useAtom } from 'jotai';
import posthog from 'posthog-js';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { major, minor, patch } from 'semver';

import { getEnvVar } from '@/env';
import { useAppStore } from '@/store';
import { lastSkippedVersionAtom } from '@/stores/atoms/appUpdate';
import { AppCapability, isCapable } from '@/utils/capabilities';

const isDev = getEnvVar('VITE_ENV') === 'development';
const isRunningInLocalhost = getEnvVar('IS_RUNNING_IN_LOCALHOST');
const PATCH_VERSION_DIFFERENCE_THRESHOLD = 1;

function isSignificantVersionDifference(
  currentVersion: string,
  targetVersion: string
) {
  const currentPatch = patch(currentVersion);
  const targetPatch = patch(targetVersion);
  if (isRunningInLocalhost) return false;
  if (major(currentVersion) !== major(targetVersion)) return true;
  if (minor(currentVersion) !== minor(targetVersion)) return true;
  // Handles the app downgrade scenario
  if (targetPatch < currentPatch) return true;
  return targetPatch - currentPatch > PATCH_VERSION_DIFFERENCE_THRESHOLD;
}

interface CheckForUpdateOptions {
  silent?: boolean;
}

export interface UpdateContextType {
  updateAvailable: Update | null;
  runUpdate: () => void;
  downloadProgress: number | null;
  checkForUpdate: (options?: CheckForUpdateOptions) => Promise<void>;
  skipUpdate: () => void;
  isUpdateCritical: boolean;
  shouldForceUpdateScreen: boolean;
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUpdate = (): UpdateContextType => {
  const context = useContext(UpdateContext);
  if (context === undefined) {
    throw new Error('useUpdate must be used within an UpdateProvider');
  }
  return context;
};

// eslint-disable-next-line react-refresh/only-export-components
export const UpdateContext = createContext<UpdateContextType | undefined>(
  undefined
);

export const UpdateProvider = ({ children }: { children: ReactNode }) => {
  const { setToastData, isFakeUser } = useAppStore((state) => state);
  const [updateAvailable, setUpdateAvailable] = useState<Update | null>(null);
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
  const [lastSkippedVersion, setLastSkippedVersion] = useAtom(
    lastSkippedVersionAtom
  );

  const checkForUpdate = useCallback(
    async (options?: CheckForUpdateOptions) => {
      if (isFakeUser) return;
      const update = await check();
      if (update?.available) {
        setUpdateAvailable(update);
        setToastData({
          title: 'Update Available',
          description: `Version ${update.version} is available.`,
          kind: 'info',
        });
      } else if (!options?.silent) {
        setToastData({
          title: 'No Updates Available',
          description: 'You are running the latest version.',
          kind: 'info',
        });
      }
    },
    [setToastData, isFakeUser]
  );

  useEffect(() => {
    if (!isCapable(AppCapability.AutoUpdate) || isDev) return;
    checkForUpdate({ silent: true });
    const pollInterval = setInterval(
      () => checkForUpdate({ silent: true }),
      millisecondsInMinute * 5
    );
    return () => {
      clearInterval(pollInterval);
    };
  }, [checkForUpdate]);

  const runUpdate = useCallback(async () => {
    let downloaded = 0;
    let contentLength: number | undefined;
    let downloadStartedAt: number | undefined;
    try {
      if (!updateAvailable) return;

      debug('Downloading update');
      await updateAvailable.download((event) => {
        switch (event.event) {
          case 'Started':
            contentLength = event.data.contentLength;
            downloadStartedAt = Date.now();
            break;
          case 'Progress':
            downloaded += event.data.chunkLength;
            if (contentLength) {
              setDownloadProgress((prev) => {
                if (!contentLength) return prev;
                return Math.max((downloaded / contentLength) * 100, prev || 0);
              });
            }
            break;
          case 'Finished':
            debug('Update downloaded');
            posthog.capture('update_download_time', {
              download_time: Date.now() - (downloadStartedAt ?? 0),
              current_version: updateAvailable.currentVersion,
              new_version: updateAvailable.version,
            });
            setDownloadProgress(null);
            break;
        }
      });
      debug('Stopping context collection before installing update');
      await invoke('stop_context_collection');
      debug('Installing app update');
      await updateAvailable.install();
      debug('Updated app installed successfully');
      debug('Relaunching app');
      invoke('relaunch_app');
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error ? err.message : 'Unknown error occurred';
      debug(`Failed to download and install update : ${JSON.stringify(err)}`);
      // Send to Sentry with additional context
      captureException(err, {
        extra: {
          downloaded,
          contentLength,
          updateVersion: updateAvailable?.version,
          currentVersion: updateAvailable?.currentVersion,
          errorDetails: errorMessage,
          fullError: JSON.stringify(err),
        },
        tags: {
          type: 'update_failure',
          component: 'AutoUpdater',
        },
      });

      setToastData({
        title: 'Failed to download and install update',
        description: errorMessage,
        kind: 'error',
      });
    }
  }, [setToastData, updateAvailable]);

  const skipUpdate = useCallback(() => {
    if (!updateAvailable) return;
    setLastSkippedVersion(updateAvailable.version);
  }, [setLastSkippedVersion, updateAvailable]);

  const isUpdateCritical = useMemo(() => {
    if (!updateAvailable) return false;
    if (
      isSignificantVersionDifference(
        updateAvailable.currentVersion,
        updateAvailable.version
      )
    )
      return true;
    if (lastSkippedVersion === updateAvailable.version) return false;
    return false;
  }, [lastSkippedVersion, updateAvailable]);

  const hasUpdateBeenSkipped = useMemo(() => {
    if (!updateAvailable) return false;
    return lastSkippedVersion === updateAvailable.version;
  }, [lastSkippedVersion, updateAvailable]);

  const shouldForceUpdateScreen = useMemo(
    () => (updateAvailable && !hasUpdateBeenSkipped) || isUpdateCritical,
    [updateAvailable, hasUpdateBeenSkipped, isUpdateCritical]
  );

  return (
    <UpdateContext.Provider
      value={{
        updateAvailable,
        runUpdate,
        downloadProgress,
        checkForUpdate,
        isUpdateCritical,
        skipUpdate,
        shouldForceUpdateScreen,
      }}
    >
      {children}
    </UpdateContext.Provider>
  );
};
