import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

import { isAuthValidQueryOptions } from '@/query/auth';
import { getLocalStorageItem } from '@/utils/localStorage';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

const accessTokenAtom = atom<string | null>(() =>
  getLocalStorageItem(ACCESS_TOKEN)
);

const refreshTokenAtom = atom<string | null>(() =>
  getLocalStorageItem(REFRESH_TOKEN)
);

export const authTokensAtom = atom((get) => ({
  accessToken: get(accessTokenAtom),
  refreshToken: get(refreshTokenAtom),
}));

export const isAuthValidQueryAtom = atomWithQuery(() =>
  isAuthValidQueryOptions()
);
