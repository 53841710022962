import { useHydrateAtoms } from 'jotai/utils';
import { queryClientAtom } from 'jotai-tanstack-query';
import { ReactNode } from 'react';

import { queryClient } from '@/query/client';

interface HydrateAtomsProps {
  children: ReactNode;
}

export function HydrateAtoms({ children }: HydrateAtomsProps) {
  useHydrateAtoms([[queryClientAtom, queryClient]]);

  return children;
}
