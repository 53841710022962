import { useCallback, useMemo } from 'react';

import { router } from '@/router';
import { logoutUser } from '@/utils/auth';

export function useErrorReception(error: Error) {
  const title = useMemo(() => {
    if (error.name === 'TokenExpiredError') {
      return 'Session expired.';
    }

    if (error.name === 'UnableToConnectToServerError') {
      return 'Cannot connect to server.';
    }

    return 'Something went wrong.';
  }, [error]);

  const message = useMemo(() => {
    if (error.name === 'TokenExpiredError') {
      return 'Your session has expired. Please login again.';
    }

    if (error.name === 'UnableToConnectToServerError') {
      return 'Please wait a moment and try again.';
    }

    if (error instanceof Error) {
      return error.message;
    }

    return JSON.stringify(error) + new Error().stack;
  }, [error]);

  const actionLabel = useMemo(() => {
    if (error.name === 'TokenExpiredError') {
      return 'Login';
    }

    if (error.name === 'UnableToConnectToServerError') {
      return 'Retry';
    }

    return 'Reload';
  }, [error]);

  const action = useCallback(() => {
    if (error.name === 'TokenExpiredError') {
      logoutUser();

      return;
    }

    if (error.name === 'UnableToConnectToServerError') {
      router.navigate({ to: '/' });

      return;
    }

    window.location.reload();
  }, [error]);

  return {
    title,
    message,
    action,
    actionLabel,
  };
}
