import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

import store from '../jotaiStore';

import { isConnectedQueryOptions } from '@/query/connection';
import { debugToast } from '@/utils/toast';

const isConnectedQueryAtom = atomWithQuery(() => isConnectedQueryOptions());

const isDisconnectedAtom = atom(false);

export const isConnectedAtom = atom((get) => {
  const { data } = get(isConnectedQueryAtom);

  return data ?? true;
});

store.sub(isConnectedQueryAtom, () => {
  const { data: isConnected, isLoading } = store.get(isConnectedQueryAtom);

  if (isLoading) return;

  if (!isConnected) {
    store.set(isDisconnectedAtom, true);

    debugToast('disconnected from server');

    return;
  }

  if (store.get(isDisconnectedAtom) && isConnected) {
    store.set(isDisconnectedAtom, false);

    debugToast('connected to server');
  }
});
